<template >
  <div>
    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Usuarios'"
        :breadcrumbps="breadcrumbps"
    />
    <UserList/>
  </div>


</template>

<script>

import PageHeader from "../components/PageHeader";
import UserList from "../components/UserList";
export default {
  name: "Home",
  components:{
    PageHeader,
    UserList
  },
  data() {
    return {
      breadcrumbps: [
        {
          text: "Lista de usuarios",
          disabled: false,
          href: "",
        },
        {
          text: "Usuarios",
          disabled: false,
          href: "",
        },
      ],
    }

  },

}
</script>

<style>
</style>
