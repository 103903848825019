<template>
  <v-container fluid>

    <v-col cols="3" sm="3" md="3" class="mt-10 mx-2 mx-sm-16">
      <v-text-field v-model="search" label="Search"></v-text-field>
    </v-col>
    <v-data-table
        :headers="headersTable"
        :items="users"
        :search="search"
        sort-by="full_name"
        class="elevation-1 mt-10 mx-2 mx-sm-16"
    >
      <template v-slot:top>
        <v-toolbar flat height="0">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-alert
                    v-for="(error, idx) in getErrors"
                    :key="idx"
                    dense
                    text
                    type="error"
                >
                  {{ error }}
                </v-alert>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                          v-model="editedItem.username"
                          label="Usuario"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                          v-model="editedItem.phone"
                          label="Telefono"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveEditUser"> Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogPerfil" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>

                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                          v-model="editedItemPerfil.description"
                          label="Descripcion"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                          v-model="editedItemPerfil.name"
                          label="Nombre"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closePerfil">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveEditPerfil"> Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  small
                  class="mr-2"
                  style="color: darkblue; font-size: 25px"
                  @click="modalEditUser(item.id)"

              >
                fas fa-user-edit
              </v-icon>
            </v-btn>
          </template>
          <span>Editar Usuario</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  small
                  class="mr-2"
                  style="color: grey; font-size: 25px"
                  @click="modalEditPerfil(item.id)"

              >
                fas fa-edit
              </v-icon>
            </v-btn>
          </template>
          <span>Editar Perfil</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon
                  small
                  class="mr-2"
                  style="color: darkred; font-size: 25px"
                  @click="deletUser(item.id)"

              >
                fas fa-trash
              </v-icon>
            </v-btn>
          </template>
          <span>Borrar Usuario</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Refrescar</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import PageHeader from './PageHeader';

import {mapActions, mapGetters} from 'vuex';

export default {
  name: "UserList",
  components: {
    PageHeader
  },
  data() {
    return {
      dialog: false,
      dialogPerfil: false,
      dialogDelete: false,
      users: [],
      uuidDelete: 0,
      editedIndex: -1,
      search: "",
      editedItem: {
        username: "",
        phone: "",
        user_id: "",
        id:""
      },
      editedItemPerfil: {
        name: "",
        description: "",
        user_id: "",
        id:""
      },

      headersTable: [
        {text: "id", value: "id"},
        {text: "Nombre", value: "Perfile.nombre"},
        {text: "Descripcion", value: "Perfile.descripcion"},
        {text: "Usuario", value: "username"},
        {text: "Correo", value: "correo"},
        {text: "Telefono", value: "telefono"},
        {text: "Acciones", value: "actions", sortable: false},
      ],
      show1: false,
      rolesItems: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['getErrors','getUser']),
    formTitle() {
      return this.editedIndex === -1 ? "Add user" : "Edit user";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },

  },
  created() {
    this.initialize();

  },
  methods: {
    ...mapActions('auth', ['getUsers', 'updateUser', 'desactiveUser','getOne','updatePerfil','desactiveUser']),
    async initialize() {
      this.users = await this.getUsers();
    },
    async modalEditUser(id) {
      const res = await this.getOne(id);
      this.editedItem.username = res.username;
      this.editedItem.phone = res.telefono;
      this.editedItem.id = res.id;
      this.editedItem.user_id = this.getUser.id;
      this.dialog= true
    },
    async modalEditPerfil(id) {
      const res = await this.getOne(id);

      this.editedItemPerfil.name = res.Perfile.nombre;
      this.editedItemPerfil.description = res.Perfile.descripcion;
      this.editedItemPerfil.id = res.Perfile.id;
      this.editedItemPerfil.user_id = this.getUser.id;
      this.dialogPerfil= true
    },


    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closePerfil() {
      this.dialogPerfil = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },


    async saveEditUser() {
      const response = await this.updateUser(this.editedItem)
      if (response){
        alert('Se edito correctamente')
        await this.initialize()
        this.editedItem.username = '';
        this.editedItem.phone = '';
        this.editedItem.id = '';
        this.editedItem.user_id = '';
        this.close();
      }else{
        alert('error al editar')
      }
    },
    async deletUser(id) {
      const a = {
        user_id:this.getUser.id,
        id

      }
      const response = await this.desactiveUser(a)
      if (response){
        alert('Se borro correctamente')
       await this.initialize();
      }else{
        alert('error al editar')
      }
    },
    async saveEditPerfil() {
      const response = await this.updatePerfil(this.editedItemPerfil)
      if (response){
        alert('Se edito correctamente')
        await this.initialize()
        this.editedItemPerfil.name = '';
        this.editedItemPerfil.descripiption = '';
        this.editedItemPerfil.id = '';
        this.editedItemPerfil.user_id = '';
        this.closePerfil();
      }else{
        alert('error al editar')
      }
    },
  },

};
</script>

<style>
</style>
